import {Directive, ElementRef, HostListener, Input, Renderer2, AfterViewInit} from '@angular/core';

declare var Fresco: any;

@Directive({
  selector: '[appFresco]'
})
export class FrescoDirective implements AfterViewInit {

  private _datafrescoGroup: string;
  private _dataFrescoGroupOptions: string;

  constructor(private el: ElementRef, private renderer: Renderer2) {
    if (el) {
      renderer.addClass(el.nativeElement, 'fresco');
    }

  }

  ngAfterViewInit(): void {
    if (this.el) {
      this.renderer.setAttribute(this.el.nativeElement, 'data-fresco-group', this._datafrescoGroup);
      this.renderer.setAttribute(this.el.nativeElement, 'data-fresco-group-options', this._dataFrescoGroupOptions);
    }
  }

  @Input('dataFrescoGroup')
  set dataFrescoGroup(groupInfo: string) {
    this._datafrescoGroup = groupInfo;

  }

  get dataFrescoGroup() {
    return this._datafrescoGroup;
  }

  @Input('dataFrescoGroupOptions')
  set dataFrescoGroupOptions(options: string) {
    this._dataFrescoGroupOptions = options;

  }

  get dataFrescoGroupOptions() {
    return this._dataFrescoGroupOptions;
  }

  @HostListener('click') onClick() {
    if (this.el) {
      console.log(this.el);
      Fresco.show(this.el.nativeElement.src);
    }
  }


}
