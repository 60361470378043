import {Pipe, PipeTransform} from '@angular/core';
import {Observable} from 'rxjs';
import {WorkService} from '../service/work.service';
import {map} from 'rxjs/operators';


@Pipe({
  name: 'instagram'
})
export class InstagramPipe implements PipeTransform {

  private embed: string = 'test';

  constructor(private workService: WorkService) {

  }

  async transform(value: string) {
    let test = await this.workService.getInstagramFeed(value);
    return test['html'];

  }



}
