<ng-container *ngTemplateOutlet="isBigEnough ? home: home_mobile"></ng-container>


<ng-template #home>
  <div class="page-wrap" id="home_content" *ngIf="imageContent" [style.display]="isBigEnough ? '':'none'"
       #mainContainer>
    <div class="ms-left" #leftContainer>
      <div class="ms-section back-full" *ngFor="let project of imageContent.left">

        <div class="back-full" [style.backgroundImage]="'url('+ project.image_path + ')'">
          <a [href]="'project/' + project.project_name">
            <div class="hiding-effect">
            <span class="description-content">
              <h1 class="text-white">
                <div [outerHTML]="project.project_title"></div>
              </h1>
              <small class="description-text">
                <div [outerHTML]="project.description"></div>
              </small>
            </span>
            </div>
          </a>
        </div>

      </div>
    </div>
    <div class="ms-right " #rightContainer>
      <div class="ms-section back-full" *ngFor="let project of imageContent.right">

        <div class="back-full" [style.backgroundImage]="'url('+  project.image_path + ')'">
          <a [href]="'project/' + project.project_name">
            <div class="hiding-effect">
            <span class="description-content">
              <h1 class="text-white">
                <div [outerHTML]="project.project_title"></div>
              </h1>
               <small class="description-text">
                <div [outerHTML]="project.description"></div>
              </small>
            </span>
            </div>
          </a>
        </div>

      </div>

    </div>
  </div>
</ng-template>


<ng-template #home_mobile>
  <div class="page-wrap-mobile" id="home_content_mobile" *ngIf="imageContent" >
    <mat-grid-list [cols]="1" class="thumb-container" gutterSize="0" rowHeight="100vh">
      <mat-grid-tile *ngFor="let project of imageContentMobile">
        <div class="back-full" [style.backgroundImage]="'url('+ project.image_path + ')'">

          <a *ngIf="project;else space" [href]="'/project/' + project.project_name "
             class="back-full" (click)="linkClick($event, '/project/' + project.project_name)"
          >

            <div class="hiding-effect" >
            <span class="description-content">
              <h1 class="text-white">
                <div [outerHTML]="project.project_title"></div>
              </h1>
              <small class="description-text">
                <div [outerHTML]="project.description"></div>
              </small>
            </span>
            </div>
          </a>
        </div>
        <ng-template #space>
        </ng-template>
      </mat-grid-tile>
    </mat-grid-list>

  </div>
</ng-template>
