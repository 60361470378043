import {
  Component,
  OnDestroy,
  OnInit,
  Renderer2,
  SecurityContext,
  AfterViewInit,
  ViewChildren, AfterViewChecked, HostListener
} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {filter, map} from 'rxjs/operators';
import {WorkService} from '../service/work.service';
import {Work} from '../interface/work';
import {NgwWowService} from 'ngx-wow';

import {DomSanitizer} from '@angular/platform-browser';
import {ProjetInfo} from '../interface/projet-info';
import {Subscription} from 'rxjs';
import {Project} from '../interface/project';
import {ProjectRoutingService} from './project-routing.service';
import {RouterAnimations} from '../animation';


declare var instgrm: any;

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked {

  work: Work;
  projInfo: ProjetInfo;
  isBigEnough = false;
  private wowSubscription: Subscription;
  private routeSubsciption: Subscription;
  private worksSubsciption: Subscription;
  private projectSubsciption: Subscription;

  /** Get handle on iframe tags in the template */
  @ViewChildren('iframe') components;

  constructor(private sanitizationService: DomSanitizer,
              private wowService: NgwWowService,
              private route: ActivatedRoute,
              private workService: WorkService,
              private renderer: Renderer2,
              private router: Router,
              private projectRoutingService: ProjectRoutingService) {
    const {index} = route.snapshot.params;
    this.projectRoutingService.broadcastProjectChange(index);
    this.routeSubsciption = this.route.paramMap.subscribe(params => {

      this.worksSubsciption = this.workService.getWorks().pipe(map(
        (works: Work[]) => works.filter(
          (workTmp: Work) => workTmp.project_name === params.get('project'))
      )).subscribe((works: Work[]) => {

        if (works.length > 0) {
          this.work = works[0];
        }
        this.projectSubsciption = this.workService.getProjectsFromUrl(this.work.url_file_conf)
          .subscribe(
            (content: ProjetInfo) => {
              this.projInfo = content;

            }
          );
      });
    });
  }


  ngOnInit(): void {
    this.router.events
      .pipe(filter(routerEvent => routerEvent instanceof NavigationEnd))
      .subscribe(() => window.scrollTo(0, 0));
    if (window.innerWidth > 576) {
      this.isBigEnough = true;
    }
  }


  ngAfterViewInit(): void {

  }

  ngAfterViewChecked(): void {
    if (window['instgrm'] !== undefined) {
      instgrm.Embeds.process();
    }
  }

  ngOnDestroy() {
    // unsubscribe (if necessary) to WOW observable to prevent memory leaks
    this.worksSubsciption.unsubscribe();
    this.projectSubsciption.unsubscribe();
    this.routeSubsciption.unsubscribe();
    this.work = null;
    this.projInfo = null;
  }


  sanitizeUrl(url: string) {
    return this.sanitizationService.sanitize(SecurityContext.URL, url);
  }

  async instaFeed(p: Project) {
    if (p.type === 'instagram') {
      const instafeed = await this.workService.getInstagramFeed(p.src);
      p.content = instafeed['html'];
    }
  }

  onload(event) {

    console.log('ici');
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {

    if (window.innerWidth < 576) {
      this.isBigEnough = false;

    } else {
      this.isBigEnough = true;
    }
  }
}
