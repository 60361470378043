<div>
  <div class="content">

    <h1 class="title-about">ABOUT</h1><br>
    <div class="content-desc">Bonjour,<br><br>

      On m’appelle Sandy, ou Vanellie pour les plus proches. Oui, car là d’où je viens, on porte son 2ème prénom. Malheureusement, au collège, on m’a imposé d’utiliser le premier. Et ce fût le début de ma double vie. <i>Non je rigole.</i> <br><br>

      Vous l’aurez remarqué, j’aime faire des blagues. Mais j’ai aussi d’autres hobbies, comme manger de bonnes choses, voyager et flâner pour découvrir de nouveaux lieux. J’aime regarder des séries, rigoler devant des vidéos LOLcat et collectionner des mugs. Récemment, je me suis même découverte une passion pour le sport, et plus
      précisément pour les cours d’OCR <i>(Obstacle Course Race)</i>.<br><br>

      Mais ma plus grande passion, ce sont <b>les arts visuels</b>, que ce soit la photographie, le graphisme, la vidéo ou la mode, etc. J’ai donc choisi de faire des études dans le graphisme. En effet c’est le domaine qui permet à ma créativité de s'épanouir, tout en regroupant plusieurs arts visuels. <b>Grâce à mon parcours, je peux aujourd’hui mettre en forme mes idées à travers différents modes d’expression </b><i>(photo, vidéo, graphisme)</i>. Ayant évoluée dans une agence à taille humaine, spécialisée dans le social média, j’ai pu enrichir mes connaissances dans les différents aspects du métier de <b>Directrice Artistique</b>. <br><br>

      Vous voulez travailler avec moi, ou me proposer de rejoindre votre équipe, <b>n’hésitez pas à me contacter !</b>
      Je suis ouverte à de nouvelles opportunités professionnelles.<br><br>

      <div class="content-about icon">
        <diV class="icon-content">
          <a href="mailto:rabe.sv@gmail.com" target="_blank">
            <h2 class="menu-top ">
              <svg class="mail-menu svg-inline--fa fa-envelope fa-w-14" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 40" x="0px" y="0px" width="32"
                   height="40" >
                <title>16- email</title>
                <g >
                  <path
                    d="M2.56,26.61l.11.12,0,0A3,3,0,0,0,4,27H28a3,3,0,0,0,1.29-.3h0l0,0a3,3,0,0,0,1.36-1.36l0,0h0A3,3,0,0,0,31,24V8a3,3,0,0,0-1.58-2.63l-.1-.1,0,0A3,3,0,0,0,28,5H4a3,3,0,0,0-1.28.3l0,0-.1.1A3,3,0,0,0,1,8V24A3,3,0,0,0,2.56,26.61ZM11.41,16.1,3,23.74V8.29ZM4.59,25l8.29-7.54,1.08,1a3,3,0,0,0,4.08,0L19.6,17l8,8Zm16.48-9.34L29,8.29V23.59ZM27.45,7,16.68,17a1,1,0,0,1-1.36,0L4.55,7Z"/>
                </g>
              </svg>
            </h2>
          </a>
          <a href="https://www.instagram.com/vanellie/" target="_blank">
            <h2>
              <fa-icon class="space-letter about-icon" [icon]="faInstagram"></fa-icon>
            </h2>
          </a>
          <a href="https://www.linkedin.com/in/sandy-vanellie-rabe-35693b76" target="_blank">
            <h2>
              <fa-icon class="space-letter about-icon" [icon]="faLinkedinIn"></fa-icon>
            </h2>
          </a>
          <a href="https://www.pinterest.fr/vanellie/" target="_blank">
            <h2>
              <fa-icon class="space-letter about-icon" [icon]="faPinterestP"></fa-icon>
            </h2>
          </a>
        </diV>
      </div>
      <br><br>


    </div>
  </div>
</div>
