import {Component, HostListener, Input, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import {Work} from '../interface/work';
import {WorkService} from '../service/work.service';
import {ActivatedRoute, NavigationEnd, Router, RouterOutlet} from '@angular/router';
import {Observable, Subscription} from 'rxjs';
import {ProjectRoutingService} from '../projects/project-routing.service';
import {RouterAnimations} from '../animation';
import {map, pairwise, share, startWith} from 'rxjs/operators';


declare var instgrm: any;

@Component({
  selector: 'app-works',
  templateUrl: './works.component.html',
  styleUrls: ['./works.component.scss']
})
export class WorksComponent implements OnInit, OnDestroy {

  @Input()
  showTitle: boolean = true;

  works: Work[];
  breakpoint: number;

  workChange$: Observable<string>;
  routeTrigger$: Observable<object>;
  private worksSubsciption: Subscription;
  currentProject: string;
  @ViewChild('worksgrid') wokrsGrid;
  gutterSize = '0.5rem';


  constructor(private router: Router,
              private route: ActivatedRoute,
              private workService: WorkService,
              private renderer: Renderer2,
              private projectRoutingService: ProjectRoutingService) {


  }

  ngOnInit(): void {

    this.changeGrid(window);

    this.worksSubsciption = this.workService.getWorks().subscribe((data: Work[]) => {
      this.works = data;
      this.workChange$ = this.projectRoutingService.projectChange;

    });

  }


  onResize(event) {
    this.breakpoint = (event.target.innerWidth <= 775) ? 2 : 3;
    this.changeGrid(event.target);
  }


  ngOnDestroy() {
    // unsubscribe (if necessary) to WOW observable to prevent memory leaks
    this.worksSubsciption.unsubscribe();
  }

  onclick(event, work: Work): void {
    if (work.project_name === this.currentProject) {
      event.preventDefault();
    }
  }


  prepareRoute(outlet: RouterOutlet) {

    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }

  changeGrid(element): void {
    if (element.innerWidth <= 576) {
      this.breakpoint = 2;
      this.gutterSize = '0.2rem';
    } else {
      this.breakpoint = 3;
      this.gutterSize = '0.5rem';
    }

  }


  @HostListener('touchstart', ['$event'])
  @HostListener('touchend', ['$event'])
  @HostListener('touchcancel', ['$event'])
  handleTouch(event) {
    const touch = event.touches[0] || event.changedTouches[0];
    let target = event.target || event.srcElement || event.currentTarget;
    // check the events

    if (  !target.classList.contains('title')) {
      console.log(target);
      target = target.parentElement.closest('.title');
      console.log(target);
    }

    if (event.type === 'touchstart') {

      this.renderer.addClass(target, 'show-desc');

    } else if (event.type === 'touchend') {
      setTimeout(() => {
        this.renderer.removeClass(target, 'show-desc');
      }, 500);
    }

  }


}
