import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {faLinkedinIn, faPinterestP, faInstagram, } from '@fortawesome/free-brands-svg-icons';
import {faEnvelope } from '@fortawesome/free-regular-svg-icons';
import {ActivatedRoute, Router} from '@angular/router';


@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit, AfterViewInit {
  faLinkedinIn = faLinkedinIn;
  faPinterestP = faPinterestP;
  faInstagram = faInstagram;
  faEnvelope = faEnvelope;

  ShouldSticky = false;

  constructor(private router: Router) {

  }

  ngOnInit(): void {
    if (this.router.url === '/') {
      this.ShouldSticky = false;
    } else {
      this.ShouldSticky = true;
    }
  }

  ngAfterViewInit(): void {

  }


}
