import { Component, OnInit } from '@angular/core';
import { faLinkedinIn, faPinterestP, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faBars } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-apropos',
  templateUrl: './apropos.component.html',
  styleUrls: ['./apropos.component.css']
})
export class AproposComponent implements OnInit {
  faLinkedinIn = faLinkedinIn;
  faPinterestP = faPinterestP;
  faInstagram = faInstagram;
  constructor() { }

  ngOnInit(): void {
  }

}
