import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {HttpClientModule} from '@angular/common/http';

import {AppComponent} from './app.component';
import {MenuComponent} from './menu/menu.component';
import {FooterComponent} from './footer/footer.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {HomepageComponent} from './homepage/homepage.component';
import {PageNotFoundComponent} from './page-not-found/page-not-found.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {WorksComponent} from './works/works.component';
import {MatGridListModule} from '@angular/material/grid-list';
import {ProjectsComponent} from './projects/projects.component';
import {NgwWowModule} from 'ngx-wow';
import {Safe} from './pipe/safe.pipe';
import {FrescoDirective} from './directive/fresco.directive';

import {MobileMenuComponent} from './mobile-menu/mobile-menu.component';
import { MainComponent } from './main/main.component';
import { AproposComponent } from './apropos/apropos.component';
import {AppRoutingModule} from './app-routing.module';
import {InstagramPipe} from './pipe/instagram.pipe';










@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    FooterComponent,
    HomepageComponent,
    PageNotFoundComponent,
    WorksComponent,
    ProjectsComponent,
    Safe,
    FrescoDirective,
    MobileMenuComponent,
    MainComponent,
    AproposComponent,
    InstagramPipe
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    MatGridListModule,
    FontAwesomeModule,
    NgwWowModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
