import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {forkJoin, Observable, throwError} from 'rxjs';
import {Work} from '../interface/work';

import {Rowcontent} from '../interface/rowcontent';
import {HomeData} from '../interface/homeData';
import {ProjetInfo} from '../interface/projet-info';
import {PageData} from '../interface/pageData';

@Injectable({
  providedIn: 'root'
})
export class WorkService {
  worksUrl = 'assets/data/project/projects.json';
  homePageUrl = 'assets/data/homepage/homepage.json';
  homePageMobileUrl = 'assets/data/homepage/homepage_mobile.json';

  constructor(private http: HttpClient) {
  }

  getWorks(): Observable<Work[]> {
    return this.http.get<Work[]>(this.worksUrl);
  }

  getProjectsFromUrl(url: string): Observable<ProjetInfo> {
    return this.http.get<ProjetInfo>(url);
  }

  getHomeData(): Observable<[HomeData, PageData[]]> {
    const homedata = this.http.get<HomeData>(this.homePageUrl);
    const homeMobile = this.http.get<PageData[]>(this.homePageMobileUrl);
    return forkJoin([homedata, homeMobile]);
  }


  getInstagramFeed(url: string): Promise<object> {
    return this.http.get('https://api.instagram.com/oembed?url=' + url + '&omitscript=true').toPromise();
  }
}
