<div *ngIf="projInfo">
  <h1 class="title-project" [innerHTML]="projInfo.title" #tile_element></h1>
  <div class="info-project" [style.top]="isBigEnough ? (tile_element.offsetHeight) + 'px':'' ">
    <div *ngIf="projInfo">
      <div class="content-desc">
        <div [innerHTML]="projInfo.description"></div>
      </div>
    </div>
  </div>
  <!-- Page content -->
  <div class="main">
    <div class="container-fluid content_full content-project" id="container_project">
      <div *ngIf="projInfo ;else work_not_found">
        <ng-container *ngIf="projInfo;else in_building">
          <div *ngFor="let row of projInfo.content;let i = index" [class]="'row'">

            <div *ngFor="let column of row.rowContent;"
                 [class]="work.col_policy === 'no-wrap' ?  row.rowcol === 'wrap' ? 'col-md' : 'col' :  row.rowcol === 'no-wrap' ? 'col' : 'col-md'  ">
              <ng-container *ngFor="let p of column;let last  = last">
                <div *ngIf="p.type === 'text';else picture_type" [innerHTML]="p.content">

                </div>

                <ng-template #picture_type>
                  <ng-container *ngIf="p.type === 'image';else video_type">
                    <div class="full-width">
                      <a style="cursor: zoom-in;" [href]="p.src" appFresco
                         [dataFrescoGroupOptions]="'thumbnails: false, ui: \'outside\', loop: true'"
                         [dataFrescoGroup]="'project_images_' + i" style="z-index: 1002">
                        <img class="img-fluid animated fadeInUp slow" src="{{p.src}}" [ngClass]="p.class ? p.class: ''">
                      </a>
                    </div>
                  </ng-container>
                  <ng-template #video_type>

                    <ng-container *ngIf="p.type === 'video'; else instagram">
                      <div class="row" [class]="p.class"
                           [style.marginTop]="p.margin_top" [style.marginBottom]="p.margin_bottom">
                        <div class="col-md video-content">
                          <video class="video-project" data-wow-delay="0.5s" controls>
                            <source [src]="p.src| safe: 'resourceUrl'" type="video/mp4">
                          </video>
                        </div>
                        <div class="col-md video-description" *ngIf="p.content !== undefined">
                          <div
                               [innerHTML]="p.content">
                          </div>
                        </div>
                      </div>
                    </ng-container>

                    <ng-template #instagram>
                      <div *ngIf="p.type === 'instagram'; else picture_mvt">
                        <div class="animated fadeInUp slow" [innerHTML]="p.src | instagram | async "
                             (change)="onload($event)"></div>

                      </div>
                      <ng-template #picture_mvt>
                        <div *ngIf="p.type === 'pictures_flow'; else unknow_type" [style.position]="'relative'"
                             [class]="p.class"
                             [style.marginTop]="p.margin_top" [style.marginBottom]="p.margin_bottom">
                          <ng-container *ngFor="let pflow of p.imgflow">
                            <a style="cursor: zoom-in;" *ngIf="pflow.type === undefined || pflow.type === 'image'"
                               [href]="pflow.src" appFresco
                               [dataFrescoGroupOptions]="'thumbnails: false, ui: \'outside\', loop: true'"
                               [dataFrescoGroup]="'project_images_' + i">
                              <img class="img-fluid animated fadeInUp slow"

                                   [class]="pflow.class"
                                   [src]="pflow.src" [style.top]="pflow.top"
                                   [style.left]="pflow.left" [style.right]="pflow.right" [style.bottom]="pflow.bottom"
                                   [style.position]="pflow.position ? pflow.position : 'absolute'"
                                   [style.width]="pflow.width">
                            </a>
                            <video
                              *ngIf="pflow.type === 'video'"
                              class="video-project" [class]="pflow.class"
                              [style.top]="pflow.top"
                              [style.left]="pflow.left" [style.right]="pflow.right" [style.bottom]="pflow.bottom"
                              [style.position]="pflow.position ? pflow.position : 'absolute'"
                              [style.width]="pflow.width"
                              data-wow-delay="0.5s" controls>
                              <source [src]="pflow.src| safe: 'resourceUrl'" type="video/mp4">
                            </video>
                            <div *ngIf="pflow.type === 'instagram'">
                              <div class="animated fadeInUp slow"
                                   [class]="pflow.class"
                                   [style.top]="pflow.top"
                                   [style.left]="pflow.left" [style.right]="pflow.right" [style.bottom]="pflow.bottom"
                                   [style.position]="pflow.position ? pflow.position : 'absolute'"
                                   [style.width]="pflow.width"
                                   [innerHTML]="pflow.src | instagram | async "
                                   (change)="onload($event)"></div>

                            </div>
                            <div *ngIf="pflow.type === 'text'"
                                 [class]="pflow.class"
                                 [style.top]="pflow.top"
                                 [style.left]="pflow.left" [style.right]="pflow.right" [style.bottom]="pflow.bottom"
                                 [style.position]="pflow.position ? pflow.position : 'absolute'"
                                 [style.width]="pflow.width"
                                 [innerHTML]="pflow.content">
                            </div>
                          </ng-container>

                        </div>
                        <ng-template #unknow_type>
                          <ng-container>
                            <h1>type inconnu</h1>
                          </ng-container>
                        </ng-template>
                      </ng-template>
                    </ng-template>
                  </ng-template>
                </ng-template>
              </ng-container>
            </div>

          </div>
        </ng-container>
        <ng-template #in_building>
          <h1>In Building...</h1>
        </ng-template>
        <div style="height: 2rem"></div>
      </div>
      <ng-template #work_not_found>

      </ng-template>

    </div>
  </div>

  <div class="works">
    <app-works [showTitle]="false"></app-works>
  </div>

</div>
