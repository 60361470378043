import { Component, OnInit } from '@angular/core';
import { faLinkedinIn, faPinterestP, faInstagram } from '@fortawesome/free-brands-svg-icons';
import {faEnvelope } from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'app-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.scss']
})
export class MobileMenuComponent implements OnInit {
  faLinkedinIn = faLinkedinIn;
  faPinterestP = faPinterestP;
  faInstagram = faInstagram;
  faEnvelope = faEnvelope;
  constructor() { }

  ngOnInit(): void {
  }

}
