import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProjectRoutingService {

  constructor() {
  }

  projectChange = new BehaviorSubject<string>(null);

  broadcastProjectChange(project: string) {
    this.projectChange.next(project);
  }
}
