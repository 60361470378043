<div style="text-align: center; padding-top: 10%; padding-bottom: 10%;">
  <small>Sandy Vanellie Rabe</small><br><br>

</div>

<div>

  <div class="marquee">
    <div class="marquee__inner">
      <div class="marquee__content">
        <h1>
          <a class="title_works  space-letter" href="/">
            Home
          </a>
        </h1>
      </div>
    </div>
  </div>



  <div class="marquee">
    <div class="marquee__inner2">
      <div class="marquee__content2">
        <a class="title_works  space-letter" href="works">
          <h1>Work</h1>
        </a>
      </div>
    </div>
  </div>

  <div class="marquee">
    <div class="marquee__inner4">
      <div class="marquee__content4">
        <a class="title_works  space-letter" href="apropos">
          <div class="title-about">About</div>
        </a>
      </div>
    </div>
  </div>

  <div class="marquee">
    <div class="marquee__inner3">
      <div class="marquee__content3">
        <a class="title_works  space-letter" href="assets/cv-rabesandy.pdf">
          <h1>CV</h1>
        </a>
      </div>
    </div>
  </div>

  <div class="marquee">
    <div class="marquee__inner3">
      <div class="marquee__content3">
        <a class="title_works  space-letter" href="https://print.vanellie.fr/">
          <h1>Print</h1>
        </a>
      </div>
    </div>
  </div>

</div>

<div class="icon-menu" style="text-align: center;">
  <a class="nav-link" href="mailto:rabe.sv@gmail.com" target="_blank">
    <h2 class="menu-top">
      <svg class="mail-mobile-menu svg-inline--fa fa-envelope fa-w-14" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 40" x="0px" y="0px" width="32"
           height="40" >
        <title>16- email</title>
        <g >
          <path
            d="M2.56,26.61l.11.12,0,0A3,3,0,0,0,4,27H28a3,3,0,0,0,1.29-.3h0l0,0a3,3,0,0,0,1.36-1.36l0,0h0A3,3,0,0,0,31,24V8a3,3,0,0,0-1.58-2.63l-.1-.1,0,0A3,3,0,0,0,28,5H4a3,3,0,0,0-1.28.3l0,0-.1.1A3,3,0,0,0,1,8V24A3,3,0,0,0,2.56,26.61ZM11.41,16.1,3,23.74V8.29ZM4.59,25l8.29-7.54,1.08,1a3,3,0,0,0,4.08,0L19.6,17l8,8Zm16.48-9.34L29,8.29V23.59ZM27.45,7,16.68,17a1,1,0,0,1-1.36,0L4.55,7Z"/>
        </g>
      </svg>
    </h2>
  </a>
  <a class="nav-link" href="https://www.instagram.com/vanellie/" target="_blank">
    <h2>
      <fa-icon class=" space-letter about-icon" [icon]="faInstagram"></fa-icon>
    </h2>
  </a>
  <a class="nav-link" href="https://www.linkedin.com/in/sandy-vanellie-rabe-35693b76" target="_blank">
    <h2>
      <fa-icon class="space-letter about-icon" [icon]="faLinkedinIn"></fa-icon>
    </h2>
  </a>
    <a class="nav-link" href="https://www.pinterest.fr/vanellie/" target="_blank">
    <h2>
      <fa-icon class="space-letter about-icon" [icon]="faPinterestP"></fa-icon>
    </h2>
  </a>
  <br>
  <br>
  <small>rabe.sv@gmail.com<br>06.21.60.35.06</small>
  <br>
</div>
