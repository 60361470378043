<!-- Footer -->
<footer class="page-footer fixed-bottom font-small">


  <!-- Copyright -->
  <div class="footer-copyright">

    <div class="marquee">
      <div class="marquee__inner">
        <div class="marquee__content">
          <br>
          © 2023 <a href="/">Sandy Vanellie RABE </a> All rights reserved

        </div>
      </div>
    </div>
  </div>

  <!-- Copyright -->

</footer>
<!-- Footer -->
