

<div class="content-works" #worksgrid>
  <h1 class="title_works" [ngClass]="showTitle ? '' : 'no-display'">Work</h1>
  <mat-grid-list [cols]="breakpoint" class="thumb-container" [gutterSize]="gutterSize" (window:resize)="onResize($event)">
    <mat-grid-tile *ngFor="let project of works;let last = last" [rowspan]="1">
      <a *ngIf="project;else space" [routerLink]="'/project/' + project.project_name "
         [routerLinkActive]="'active-link'" class="back-full"
         (click)="onclick($event,project)"
      >
        <div class="back-full" [style.backgroundImage]="'url('+ project.project_image_path + ')'">
        </div>
        <div class="title"><span>{{ project.project_title }}</span></div>
      </a>
      <ng-template #space>
      </ng-template>
    </mat-grid-tile>

  </mat-grid-list>

</div>
