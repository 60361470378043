import {trigger, transition, group, query, style, animate, animateChild, keyframes, stagger} from '@angular/animations';

export class RouterAnimations {
  static routeSlide =
    trigger('routeAnimations', [
      transition('ProjectPage => *', [


        group([
          query(':leave',
            style({
              display: 'block', width: '100%'
            }),
            {optional: true}),
          query(':leave', [
            style({
              opacity: 1
            }),
            animate('0.5s ease-in-out',
              style({
                opacity: 0
              }))
          ], {optional: true}),
        ])
      ]),
      transition('WorksPage => *', [
        query(':leave',
          style({
            display: 'block', width: '100%'
          }),
          {optional: true}),
        group([
          query(':leave, .title_works', [
            animate('1s', keyframes([
                style({
                  opacity: 0.5, color: '#ffffff', offset: 0
                }),
                style({
                  opacity: 0.1, color: '#ffffff', offset: 0.1
                })
                ,
                style({
                  opacity: 0, color: '#ffffff', offset: 1
                })
              ])
            )

          ], {optional: true})

        ])
      ]),
      transition('*  => ProjectPage', [

        query(':enter',
          style({
            display: 'block', width: '100%'
          }),
          {optional: true}),
        group([
          query(':enter', [
            style({
              opacity: 0
            }),
            animate('1s', keyframes([
              style({
                  opacity: 0, offset: 0.5
                }
              ),
              style({
                  opacity: 0.5, offset: 0.6
                }
              ),
              style({
                  opacity: 1, offset: 1
                }
              )]))
          ], {optional: true}),

        ])
      ]),
    ]);
}
