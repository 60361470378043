<nav class="navbar navbar-expand-sm navbar-light bg-transparent" [ngClass]="ShouldSticky ? 'sticky-top' : 'fixed-top'">
  <div class="container-fluid">
    <!-- Toggler/collapsibe Button -->
    <a class="navbar-toggler ml-auto toggle-menu"

       href="/mobilemenu"
       style="top: 0.5rem;right: 0.5rem;"
    >
      <img src="/assets/plus.svg" width="24" height="24">
    </a>
    <!-- Navbar links -->
    <div class="collapse navbar-collapse" id="collapsibleNavbar">
      <!-- Brand -->
      <a class="navbar-brand" href="/">
        <h2 class="brand-text space-letter menu-top">Sandy Vanellie Rabe</h2>
      </a>

      <ul class="navbar-nav ml-auto">
        <li class="nav-item">
          <a class="nav-link space-letter" href="works">
            <h2 class="menu-top">SEE MORE</h2>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link space-letter" disabled="disabled">
            <h2 class="menu-top">/</h2>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link space-letter" href="https://print.vanellie.fr/">
            <h2 class="menu-top">PRINT</h2>
          </a>
        </li>
      </ul>

      <ul class="navbar-nav ml-auto">
        <li class="nav-item">
          <a class="nav-link space-letter" href="apropos">
            <h2 class="menu-top">ABOUT</h2>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link space-letter" disabled="disabled">
            <h2 class="menu-top">/</h2>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link space-letter" href="assets/cv-rabesandy.pdf">
            <h2 class="menu-top">CV</h2>
          </a>
        </li>

      </ul>

      <ul class="navbar-nav ml-auto">
        <li class="nav-item">
          <a class="nav-link" href="mailto:rabe.sv@gmail.com" target="_blank">
            <h2 class="menu-top">
              <svg class="mail-top-menu svg-inline--fa fa-envelope fa-w-14" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 40" x="0px" y="0px" width="32"
                   height="40">
                <title>16- email</title>
                <g >
                  <path
                    d="M2.56,26.61l.11.12,0,0A3,3,0,0,0,4,27H28a3,3,0,0,0,1.29-.3h0l0,0a3,3,0,0,0,1.36-1.36l0,0h0A3,3,0,0,0,31,24V8a3,3,0,0,0-1.58-2.63l-.1-.1,0,0A3,3,0,0,0,28,5H4a3,3,0,0,0-1.28.3l0,0-.1.1A3,3,0,0,0,1,8V24A3,3,0,0,0,2.56,26.61ZM11.41,16.1,3,23.74V8.29ZM4.59,25l8.29-7.54,1.08,1a3,3,0,0,0,4.08,0L19.6,17l8,8Zm16.48-9.34L29,8.29V23.59ZM27.45,7,16.68,17a1,1,0,0,1-1.36,0L4.55,7Z"/>
                </g>
              </svg>
            </h2>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="https://www.instagram.com/vanellie/" target="_blank">
            <h2 class="menu-top">
              <fa-icon class="text-grey space-letter fa-lg" [icon]="faInstagram"></fa-icon>
            </h2>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="https://www.linkedin.com/in/sandy-vanellie-rabe-35693b76" target="_blank">
            <h2 class="menu-top">
              <fa-icon class="text-grey space-letter fa-lg" [icon]="faLinkedinIn"></fa-icon>
            </h2>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="https://www.pinterest.fr/vanellie/" target="_blank">
            <h2 class="menu-top">
              <fa-icon class="text-grey  space-letter fa-lg" [icon]="faPinterestP"></fa-icon>
            </h2>
          </a>
        </li>
      </ul>
    </div>
  </div>
</nav>

