import {Component, OnInit, AfterViewInit, HostListener, ViewChild, Renderer2, ElementRef} from '@angular/core';

import {WorkService} from '../service/work.service';
import {Work} from '../interface/work';
import {HomeData} from '../interface/homeData';
import {PageData} from '../interface/pageData';
import {Router} from '@angular/router';
// You add it here
declare var jQuery: any;


@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})


export class HomepageComponent implements OnInit, AfterViewInit {
  top = 0;

  imageContent: HomeData;
  imageContentMobile: PageData[];
  ismultiscroll = false;
  hasbeenAlreadyMulti = false;
  isBigEnough = false;
  @ViewChild('leftContainer') leftContainer;
  @ViewChild('rightContainer') rightContainer;
  defaultTouch = {x: 0, y: 0, time: 0};

  constructor(private renderer: Renderer2, private workService: WorkService, private router: Router) {


  }

  ngOnInit(): void {

    this.workService.getHomeData().subscribe((data: [HomeData, PageData[]]) => {
      this.imageContent = data[0];
      this.imageContentMobile = data[1];
      if (window.innerWidth >= 576) {
        this.ismultiscroll = true;
        this.hasbeenAlreadyMulti = true;
        this.isBigEnough = true;
        this.activateMultiscroll();

      }


    });

  }

  ngAfterViewInit(): void {

  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event) {

  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {

    if (window.innerWidth < 576) {
      if (this.isBigEnough) {
        this.removeMultiScroll();
        this.ismultiscroll = false;
        this.isBigEnough = false;
      }

    } else {
      if (!this.isBigEnough) {
        this.activateMultiscroll();
        this.ismultiscroll = true;
        this.isBigEnough = true;
      }
    }
  }


  activateMultiscroll() {
    jQuery(document).ready(function() {
      jQuery('#home_content').multiscroll(
        {
          verticalCentered: true,
          scrollingSpeed: 500,
          easing: 'easeInQuart',
          menu: false,
          sectionsColor: [],
          navigation: true,
          navigationPosition: 'right',
          navigationColor: '#000',
          navigationTooltips: [],
          loopBottom: true,
          loopTop: true,
          css3: true,
          paddingTop: 0,
          paddingBottom: 0,
          normalScrollElements: null,

          keyboardScrolling: true,
          touchSensitivity: 5,
        }
      );

    });
  }

  removeMultiScroll() {
    jQuery('#multiscroll-nav').remove();
    jQuery('#home_content').multiscroll.destroy();
    jQuery('#home_content').multiscroll = null;
    jQuery('body, html').css('overflow', '');
    jQuery('body, html').css('height', '');
    jQuery('body, html').removeClass(function(index, className) {
      return (className.match(/(^|\s)ms-viewing\S+/g) || []).join(' ');
    });
    jQuery('.ms-left').css('height', '');
    jQuery('.ms-left').css('position', '');
    jQuery('.ms-left').css('left', '');
    jQuery('.ms-left').css('top', '');
    jQuery('.ms-left').css('touch-action', '');
    jQuery('.ms-left').css('transform', '');
    jQuery('.ms-left').css('width', '');
    jQuery('.ms-right').css('height', '');
    jQuery('.ms-right').css('position', '');
    jQuery('.ms-right').css('right', '');
    jQuery('.ms-right').css('top', '');
    jQuery('.ms-right').css('touch-action', '');
    jQuery('.ms-right').css('transform', '');
    jQuery('.ms-right').css('width', '');
    jQuery('.ms-section').css('height', '');
    jQuery('.ms-section').removeClass('active');
    jQuery('.ms-section').removeClass('ms-table');
    jQuery('.ms-right').removeClass('ms-easing');
    jQuery('.ms-left').removeClass('ms-easing');
    jQuery('.ms-tableCell').replaceWith(function() {
      return $(':first', this);
    });
  }


  @HostListener('touchstart', ['$event'])
  @HostListener('touchend', ['$event'])
  @HostListener('touchcancel', ['$event'])
  handleTouch(event) {
    const touch = event.touches[0] || event.changedTouches[0];

    let target = event.target || event.srcElement || event.currentTarget;
    // check the events
    if (!target.classList.contains('hiding-effect')) {
      target = target.parentElement.closest('.hiding-effect');
    }

    if (event.type === 'touchstart') {

      this.renderer.addClass(target, 'show-desc');

    } else if (event.type === 'touchend') {
      setTimeout(() => {
        this.renderer.removeClass(target, 'show-desc');
      }, 1000);
    }
  }

  linkClick(event, href) {
    event.preventDefault();
    event.stopPropagation();
    setTimeout(() => {
      this.router.navigate([href]);
    }, 500);
  }


}



